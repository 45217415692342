<template>
  <section class="dashboard">
   
    <div class="page-header">
      <h3 class="page-title">
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
          <i class="mdi mdi-check"></i>
        </span> Agregar {{ $options.label }}</h3>
        
        <!--
          <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                <span></span>Vista general<i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
        -->
    </div>


    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Datos de la {{ $options.label }}</h4>
            <formPhase :id="parseInt($route.params.id)" @afterSave="afterSave"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import formPhase from "./_form";

export default {
  routeName:'phases',
  label:'Fase',
  name:"updatePhase",
  components: {
    formPhase
  },
  methods:{

    afterSave(){

      this.$router.push(this.alias());

      
    }

  }

}



</script>


